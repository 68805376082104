<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 mb-0">
            <div class="d-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="uil uil-house-user display-6 text-primary"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }}
                </h4>
                <small class="d-block font-size-13 text-muted fw-normal"
                  >Add Applicant Information, Bank Account and Supporting
                  Documents
                </small>
              </div>

              <div class="ms-lg-auto ms-1 text-nowrap">
                <router-link
                  :to="{
                    name: prevRoute.name || 'Applicant',
                    query: {
                      status: prevRoute.query.status || $route.query.status,
                    },
                  }"
                  class="btn btn-outline-info fw-medium me-2"
                >
                  <i class="uil uil-arrow-left me-1"></i> Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div id="applicant-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls=""
              v-b-toggle.accordion-1
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        <i class="uil uil-building font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">
                      Applicant Information
                      <span
                        class="error font-size-18"
                        v-if="$v.formData.$error"
                      >
                        <i class="uil uil-exclamation-circle"></i>
                      </span>
                    </h5>
                    <p class="text-muted text-truncate mb-0">
                      Please fill in the related fields below
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#applicant-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-3 border-top">
                <div class="row placeholder-glow" v-if="loading">
                  <div class="col-12 mb-3">
                    <div>
                      <span class="placeholder col-4 col-lg-2 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-4 mb-3">
                    <div>
                      <span class="placeholder col-8 col-lg-4 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-4 mb-3">
                    <div>
                      <span class="placeholder col-8 col-lg-4 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-4 mb-3">
                    <div>
                      <span class="placeholder col-8 col-lg-4 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div>
                      <span class="placeholder col-4 col-lg-2 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-4 mb-3">
                    <div>
                      <span class="placeholder col-8 col-lg-4 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-4 mb-3">
                    <div>
                      <span class="placeholder col-8 col-lg-4 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                  <div class="col-4 mb-3">
                    <div>
                      <span class="placeholder col-8 col-lg-4 rounded"></span>
                    </div>
                    <div>
                      <span class="placeholder col-10 col-lg-8 rounded"></span>
                    </div>
                  </div>
                </div>
                <form v-else>
                  <div class="mb-3 position-relative">
                    <label>Name of Entity</label>
                    <input
                      v-model.trim="formData.company_name"
                      type="text"
                      class="form-control"
                      placeholder="Name of Entity"
                      :class="{
                        'is-invalid':
                          submitted && $v.formData.company_name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.formData.company_name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.company_name.required"
                        >Company Name is required.</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Date of Registration</label>
                        <date-picker
                          v-model="formData.date_of_registration"
                          type="date"
                          value-type="format"
                          format="MMM DD, YYYY"
                          placeholder="Date of Registration"
                          :disabled="loading"
                          :disabled-date="notBeforeToday"
                          :editable="false"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.formData.date_of_registration.$error,
                          }"
                        ></date-picker>

                        <div
                          v-if="
                            submitted && $v.formData.date_of_registration.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="!$v.formData.date_of_registration.required"
                            >Business Registration No. is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Business Registration No.</label>
                        <input
                          v-model.trim="formData.business_registration_no"
                          type="text"
                          class="form-control"
                          placeholder="20001112222 (123456-A)"
                          :class="{
                            'is-invalid':
                              submitted &&
                              $v.formData.business_registration_no.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitted &&
                            $v.formData.business_registration_no.$error
                          "
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              !$v.formData.business_registration_no.required
                            "
                            >Business Registration No. is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label>Type of Entity</label>
                        <select
                          class="form-control select2"
                          v-model="formData.type_of_entity"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.type_of_entity.$error,
                          }"
                        >
                          <option value="">Select</option>
                          <option
                            v-for="(value, index) in typeEntity"
                            :key="index"
                            :value="value"
                          >
                            {{ value.name }}
                          </option>
                        </select>

                        <div
                          v-if="submitted && $v.formData.type_of_entity.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.type_of_entity.required"
                            >Type of Entity is required.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="mb-3 position-relative">
                        <label>Business Address</label>
                        <input
                          v-model.trim="formData.business_address"
                          type="text"
                          class="form-control"
                          placeholder="Business Address"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.business_address.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitted && $v.formData.business_address.$error
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.business_address.required"
                            >Business Address is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Telephone Number</label>
                        <input
                          v-model.trim="formData.business_tel"
                          type="text"
                          class="form-control"
                          placeholder="Telephone Number"
                          @input="
                            inputNumberOnly($event, 'formData', 'business_tel')
                          "
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.business_tel.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.business_tel.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.business_tel.required"
                            >Telephone Number is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>E-Mail Address</label>
                        <input
                          v-model.trim="formData.business_email"
                          type="text"
                          class="form-control"
                          placeholder="E-Mail Address"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.business_email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.business_email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.business_email.required"
                            >E-mail address is required.</span
                          >
                          <span v-if="!$v.formData.business_email.email"
                            >E-mail address should be a valid email.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Contact Person</label>
                        <input
                          v-model.trim="formData.contact_person"
                          type="text"
                          class="form-control"
                          placeholder="Contact Person"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.contact_person.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.contact_person.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.contact_person.required"
                            >Contact Person is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Position</label>
                        <input
                          v-model.trim="formData.position"
                          type="text"
                          class="form-control"
                          placeholder="Position"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.position.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.position.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.position.required"
                            >Position is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Contact Person Phone No.</label>
                        <input
                          v-model.trim="formData.contact_tel"
                          type="text"
                          class="form-control"
                          placeholder="Contact Person Tel No."
                          @input="
                            inputNumberOnly($event, 'formData', 'contact_tel')
                          "
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.contact_tel.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.contact_tel.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.contact_tel.required"
                            >Contact Person Phone No. is required.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <label>Contact Person E-Mail Address</label>
                        <input
                          v-model.trim="formData.contact_email"
                          type="text"
                          class="form-control"
                          placeholder="Contact Person E-Mail Address"
                          :class="{
                            'is-invalid':
                              submitted && $v.formData.contact_email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.formData.contact_email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.formData.contact_email.required"
                            >Contact Person E-Mail Address is required.</span
                          >
                          <span v-if="!$v.formData.contact_email.email"
                            >Contact Person E-Mail Address should be a valid
                            email.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="my-3">
                    <span class="badge bg-soft-primary font-size-15"
                      >Director Information</span
                    >
                  </div>
                  <div
                    class="row"
                    v-for="(value, index) in formData.directors"
                    :key="index"
                  >
                    <div class="col-md" :class="{'col-md-6':index==0 }">
                      <div class="mb-3 position-relative">
                        <label>{{ index+1 }}. Director Name</label>
                        <input
                          v-model.trim="value.name"
                          type="text"
                          class="form-control"
                          placeholder="Director Name"
                        />
                      </div>
                    </div>
                    <div class="col-md" :class="{'col-md-6':index==0 }">
                      <div class="mb-3 position-relative">
                        <label>NRIC</label>
                        <input
                          v-model.trim="value.nric"
                          type="text"
                          class="form-control"
                          placeholder="Director NRIC"
                          @input="
                              inputNumberOnlyV4(
                              $event,
                              'formData',
                              'directors',
                              'nric',
                              index
                            )
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-auto col-6 text-lg-center" :class="{'d-none':index==0  }">
                      <label>&nbsp;</label>
                      <div class="mb-3 position-relative" v-if="index!==0 ">
                        <button
                        type="button"
                        class="btn btn-danger btn-soft-danger waves-effect waves-light border-0 fw-medium"
                        v-if="create_modify_permission"
                        @click="
                          formData.directors.splice(index, 1);
                        "
                      >
                        <i class="uil uil-trash-alt"></i> 
                      </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-end" v-if="create_modify_permission">
                    <button
                      type="button"
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0 me-2"
                      @click="formData.directors.pop()"
                      :disabled="formData.directors.length < 2"
                    >
                      <i class="uil uil-minus-circle"></i>
                      Remove
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0"
                      @click="
                        formData.directors.push({
                          name: '',
                          nric: ''
                        })
                      "
                      :disabled="formData.directors.length >= 20"
                    >
                      <i class="uil uil-plus-circle"></i> Add New Director
                    </button>
                    
                  </div>
              

                  <div class="my-3">
                    <span class="badge bg-soft-primary font-size-15"
                      >Status</span
                    >
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div
                        class="mb-3 position-relative d-flex align-items-center"
                      >
                        <label class="me-3 mb-0">Applicant Status</label>
                        <div class="form-check form-switch form-switch-md">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            v-model="formData.active"
                            true-value="1"
                            false-value="0"
                          />
                        </div>
                      </div>
                      <div class="mb-3" v-if="formData.active == 0">
                        <label>Inactive Reason</label>
                        <textarea
                          class="form-control"
                          placeholder="Inactive Reason"
                          rows="3"
                          v-model="formData.reason_inactive"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-2
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        <i class="uil uil-credit-card font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">
                      Bank Account Information
                      <span
                        class="error font-size-18"
                        v-if="$v.formData2.$error"
                      >
                        <i class="uil uil-exclamation-circle"></i>
                      </span>
                    </h5>
                    <p class="text-muted text-truncate mb-0">
                      This information appears in Part C (Credit Paper)
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#applicant-accordion"
            >
              <div class="p-3 border-top">
                <div>
                  <div
                    class="row"
                    v-for="(v, index) in $v.formData2.banks.$each.$iter"
                    :key="index"
                  >
                    <div
                      class="form-group col-md"
                      :class="{ 'form-group--error': v.$error }"
                    >
                      <div class="mb-3 position-relative">
                        <label>Bank Name</label>
                        <input
                          v-model.trim="v.bank_name.$model"
                          type="text"
                          class="form-control"
                          placeholder="Bank Name"
                          :class="{
                            'is-invalid': submitted && v.bank_name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && v.bank_name.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!v.bank_name.required"
                            >Bank Name is required.</span
                          >
                          <span v-if="!v.bank_name.minLength"
                            >Bank Name must have at least
                            {{
                              v.bank_name.$params.minLength.min
                            }}
                            letters.</span
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-group col-md"
                      :class="{ 'form-group--error': v.$error }"
                    >
                      <div class="mb-3 position-relative">
                        <label>Bank Branch</label>
                        <input
                          v-model.trim="v.branch.$model"
                          type="text"
                          class="form-control"
                          placeholder="Bank Branch"
                          :class="{
                            'is-invalid': submitted && v.branch.$error,
                          }"
                        />
                        <div
                          v-if="submitted && v.branch.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!v.branch.required"
                            >Branch is required.</span
                          >
                          <span v-if="!v.branch.minLength"
                            >Branch must have at least
                            {{ v.branch.$params.minLength.min }} letters.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group col-md"
                      :class="{ 'form-group--error': v.$error }"
                    >
                      <div class="mb-3 position-relative">
                        <label>Account Number</label>
                        <input
                          v-model.trim="v.account_number.$model"
                          type="text"
                          class="form-control"
                          @input="
                            inputNumberOnlyV4(
                              $event,
                              'formData2',
                              'banks',
                              'account_number',
                              index
                            )
                          "
                          placeholder="Account Number"
                          :class="{
                            'is-invalid': submitted && v.account_number.$error,
                          }"
                        />
                        <div
                          v-if="submitted && v.account_number.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!v.account_number.required"
                            >Account Number is required.</span
                          >
                          <span v-if="!v.account_number.minLength"
                            >Account Number must have at least
                            {{
                              v.account_number.$params.minLength.min
                            }}
                            letters.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group col-md"
                      :class="{ 'form-group--error': v.$error }"
                    >
                      <div class="mb-3 position-relative">
                        <label>Facility</label>
                        <input
                          v-model.trim="v.facility.$model"
                          type="text"
                          class="form-control"
                          placeholder="Current Account-i"
                          :class="{
                            'is-invalid': submitted && v.facility.$error,
                          }"
                        />
                        <div
                          v-if="submitted && v.facility.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!v.facility.required"
                            >Facility is required.</span
                          >
                          <span v-if="!v.facility.minLength"
                            >Facility must have at least
                            {{
                              v.facility.$params.minLength.min
                            }}
                            letters.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group col-md"
                      :class="{ 'form-group--error': v.$error }"
                    >
                      <div class="mb-3 position-relative">
                        <label>Limit
                          <span
                            class="text-success"
                            v-if="v.limit.$model"
                          >
                            ({{
                              convertCurrencyFormat(
                                v.limit.$model||
                                  0,
                                false
                              )
                            }})</span
                          >
                        </label>
                        <input
                          v-model.trim="v.limit.$model"
                          @input="
                           inputNumberOnlyV4(
                              $event,
                              'formData2',
                              'banks',
                              'limit',
                              index
                            )
                          "
                          type="text"
                          class="form-control"
                          placeholder="Limit"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.formData2.banks.$error }"
                  ></div>
                  <div class="error" v-if="!$v.formData2.banks.minLength">
                    List must have at least
                    {{ $v.formData2.banks.$params.minLength.min }} elements.
                  </div>
                  <div class="error" v-else-if="!$v.formData2.banks.required">
                    List must not be empty.
                  </div>
                  <div class="error" v-else-if="$v.formData2.banks.$error">
                    Bank account list is invalid.
                  </div>

                  <div class="my-2 text-end" v-if="create_modify_permission">
                    <button
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0 me-2"
                      @click="formData2.banks.pop()"
                      :disabled="formData2.banks.length < 2"
                    >
                      <i class="uil uil-minus-circle"></i>
                      Remove
                    </button>
                    <button
                      class="btn btn-primary btn-soft-primary waves-effect waves-light border-0"
                      @click="
                        formData2.banks.push({
                          bank_name: '',
                          branch: '',
                          account_number: '',
                          facility: 'Current Account-i',
                          limit: '',
                        })
                      "
                      :disabled="formData2.banks.length > 3"
                    >
                      <i class="uil uil-plus-circle"></i> Add New Bank
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls=""
              v-b-toggle.accordion-3
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        <i class="uil uil-file-alt font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Supporting Documents</h5>
                    <p class="text-muted text-truncate mb-0">
                      Upload the related document of the applicant (Combine PDF Tool: <a href="https://combinepdf.com" target="_blank" title="Combine PDF Tool">https://combinepdf.com</a>)
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              data-parent="#applicant-accordion"
            >
              <div class="p-3 border-top">
                <div class="row">
                  <div
                    class="col-xl-4 col-sm-6"
                    v-for="(value, index) in formData.supporting_docs"
                    :key="index"
                  >
                    <div class="card shadow-none bg-light">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-16 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                                {{ index + 1 }}
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>

                              <p class="text-success" v-if="value.status == 1" :class="{'mb-0': !create_modify_permission}">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>

                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= '',
                                    value.remark= 'Pending',
                                    value.checkYesNo = ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>


                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0" :class="{'mb-0': !create_modify_permission}">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading[1] && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  v-if="create_modify_permission"
                                  class="form-control"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading[1]"
                                  :id="value.id"
                                  @change="onChangePDF"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-end">
                    <button v-if="formData.supporting_docs.length>18"  @click="formData.supporting_docs.pop()" class="btn btn-primary btn-soft-primary waves-effect waves-light border-0 me-2"><i class="uil uil-minus-circle"></i> Remove </button>
                    <button class="btn btn-primary btn-soft-primary waves-effect waves-light border-0" @click='formData.supporting_docs.push({
                      id: formData.supporting_docs.length+1,
                      name: "Additional Documents",
                      status: 0,
                      files: "",
                      file_name: "",
                      file_size: "",
                      appendix: "",
                      remark: "Pending",
                      custom_remark: "",
                      checkYesNo: "",
                    })'>
                    <i class="uil uil-plus-circle"></i> Add More Documents
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls=""
              v-b-toggle.accordion-4
            >
              <div class="p-3">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        <i class="uil uil-university font-size-18"></i>
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Current Facilities Limit</h5>
                    <p class="text-muted text-truncate mb-0">
                      Pre-Factoring and Factoring
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-4"
              accordion="my-accordion"
              data-parent="#applicant-accordion"
            >
              <div class="p-3 border-top">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="mb-3 position-relative">
                      <label>Pre-Factoring Current Limit 
                        <span
                            class="text-success"
                            v-if="formData.current_facilities_limit.pre_factoring.limit"
                          >
                            ({{
                              convertCurrencyFormat(
                                formData.current_facilities_limit.pre_factoring.limit||
                                  0,
                                false
                              )
                            }})</span
                          >
                      </label>
                      <input
                        v-model.trim="
                          formData.current_facilities_limit.pre_factoring.limit
                        "
                        @input="
                          inputNumberOnlyV5(
                            $event,
                            'formData',
                            'current_facilities_limit',
                            'pre_factoring',
                            'limit',
                          )
                        "
                        type="text"
                        class="form-control"
                        placeholder="Pre-Factoring Current Limit"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="mb-3 position-relative">
                      <label>Factoring Current Limit
                        <span
                            class="text-success"
                            v-if="formData.current_facilities_limit.factoring.limit"
                          >
                            ({{
                              convertCurrencyFormat(
                                formData.current_facilities_limit.factoring.limit||
                                  0,
                                false
                              )
                            }})</span
                          >
                      </label>
                      <input
                        v-model.trim="
                          formData.current_facilities_limit.factoring.limit
                        "
                        @input="
                          inputNumberOnlyV5(
                            $event,
                            'formData',
                            'current_facilities_limit',
                            'factoring',
                            'limit',
                          )
                        "
                        type="text"
                        class="form-control"
                        placeholder="Factoring Current Limit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md text-start">
        <div class="col-md-12 text-muted" v-if="formData.date_created">
          Created by {{ formData.created_by }} at {{ formData.date_created
          }}<br />
        </div>
        <div class="col-md-12 text-muted" v-if="formData.date_updated">
          Last updated by {{ formData.last_update_by }} at
          {{ formData.date_updated }}
        </div>
      </div>
      <div class="col text-end">
        <button
          class="btn btn-primary btn-soft-primary border-0"
          @click="$router.replace({ name: 'Applicant' }).catch(() => {})"
        >
          <i class="uil uil-times"></i> Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary ms-2"
          @click="save"
          v-if="create_modify_permission"
          :disabled="loading || buttonLoading[0]"
        >
          <span v-if="buttonLoading[0]"
            ><i class="uil uil-check me-1"></i> Updating...</span
          >
          <span v-else> <i class="uil uil-check"></i> Update </span>
          <span
            class="spinner-border spinner-border-sm ms-2"
            v-if="buttonLoading[0]"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
      <!-- end col -->
    </div>
    <Lottie
      :path="'/animate/loading_blue.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import { required, email, minLength } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== "YOUR_API_ID";
};
export const equal2B = (value) => {
  return value !== "YOUR_API_HASH";
};
/**
 * Starter page
 */
const PageTitle = "Edit Applicant";

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    return {
      id: "",
      prevRoute: {
        query: {
          status: "",
        },
      },
      uploadProgress: 0,
      returnData: {},
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type: "",
      showLottie: false,
      typeEntity: [
        { name: "Private Limited", value: "private_limited" },
        { name: "Partnership", value: "partnership" },
        { name: "Proprietorship", value: "proprietorship" },
      ],
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "Applicants",
          to: "/applicants",
        },
        {
          text: "Edit Applicant",
          active: true,
        },
      ],
      formData: {
        company_name: "",
        business_registration_no: "",
        date_of_registration: "",
        type_of_entity: "",
        business_address: "",
        business_tel: "",
        business_email: "",
        contact_person: "",
        position: "",
        contact_tel: "",
        contact_email: "",
        directors: [
          { name: "", nric: "" },
          { name: "", nric: "" },
        ],
        banks: [
          {
            bank_name: "",
            branch: "",
            account_number: "",
            facility: "",
            limit: "",
          },
        ],
        supporting_docs: [
          {
            id: 1,
            name: "Letter of Award or Surat Setuju Terima (SST)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix A",
            remark: "Appendix A",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 2,
            name: "Purchase Order (PO) or Warrant or Indent",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix B",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 3,
            name: "Contract",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix C",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 4,
            name: "Profile of Company",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix D",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 5,
            name: "Profile of Directors or Partners or Proprietor",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix E",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 6,
            name: "Latest Six Months Bank Statements",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix F",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 7,
            name: "Latest One Year Financial Statements",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "Appendix G",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 8,
            name: "Latest Month EPF Contribution for Staff (Penyata Caruman KWSP)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 9,
            name: "Identity Card of All Directors or Partners or Proprietor",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 10,
            name: "Applicant Explanation of Latest Financial Position",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 11,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 12,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 13,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 14,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 15,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 16,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 17,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          },
          {
            id: 18,
            name: "Additional Documents",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
            appendix: "",
            remark: "Pending",
            custom_remark: "",
            checkYesNo: "",
          }
        ],
        current_facilities_limit: {
          pre_factoring: {
            limit: 0,
          },
          factoring: {
            limit: 0,
          },
        },
        created_by: "",
        reason_inactive: "",
        active: 1,
      },
      formData2: {
        banks: [
          {
            bank_name: "",
            branch: "",
            account_number: "",
            facility: "",
            limit: "",
          },
        ],
      },
      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      create_modify_permission: false
    };
  },
  validations: {
    formData: {
      company_name: {
        required,
      },
      business_registration_no: {
        required,
      },
      date_of_registration: {
        required,
      },
      type_of_entity: {
        required,
      },
      business_address: {
        required,
      },
      business_tel: {
        required,
      },
      business_email: {
        required,
        email,
      },
      contact_person: {
        required,
      },
      position: {
        required,
      },
      contact_tel: {
        required,
      },
      contact_email: {
        required,
        email,
      },
    },
    formData2: {
      banks: {
        required,
        minLength: minLength(1),
        $each: {
          bank_name: {
            required,
            minLength: minLength(2),
          },
          branch: {
            required,
            minLength: minLength(2),
          },
          account_number: {
            required,
            minLength: minLength(2),
          },
          facility: {
            required,
            minLength: minLength(2),
          },
          limit: {},
        },
      },
    },
  },
  middleware: "authentication",
  async mounted() {
    await this.reload();
    this.title = PageTitle;
    this.items[2].text = PageTitle;
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    await this.getData();
  },
  created() {},
  methods: {
    convertFileSize(fileSize) {
      var formattedFileSize = "";

      if (fileSize < 1024) {
        formattedFileSize = fileSize + " bytes";
      } else if (fileSize < 1048576) {
        // 1024 * 1024
        formattedFileSize = (fileSize / 1024).toFixed(2) + " KB";
      } else if (fileSize < 1073741824) {
        // 1024 * 1024 * 1024
        formattedFileSize = (fileSize / 1048576).toFixed(2) + " MB";
      } else {
        formattedFileSize = (fileSize / 1073741824).toFixed(2) + " GB";
      }
      return formattedFileSize;
    },
    async onChangePDF(e) {
      console.log(e.target.id);
      let file = e.target.files[0];
      console.log(file);
      this.formData.supporting_docs.forEach((element) => {
        if (element.id == e.target.id) {
          element.status = 2;
          element.file_name = file.name;
          element.file_size = this.convertFileSize(file.size);
          element.files = file;
          this.uploadFile(element);
        }
      });
      // this.formSuccessData.transactionReceipt = file
      // this.formSuccessData.transactionReceiptFile = file
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][childModel] = numericValue;
    },
    inputNumberOnlyV5(event, parentModel, parent, childModel, child) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][parent][childModel][child] = numericValue;
    },
    inputNumberOnlyV4(event, parentModel, parent, childModel, index) {
      const numericValue = event.target.value.replace(/[^0-9-]/g, "");
      this[parentModel][parent][index][childModel] = numericValue;
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    async getData() {
      this.$Progress.start();
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accountDbCode", this.$route.params.id);
      console.log(this.$route.params.id);
      axios({
        method: "post",
        url:  appConfig.APIHost + "controller/applicant/getApplicantsDetailByID",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);
            // if (this.$route.params.id == 1002){
            //   this.formData = resData.data[0];
            //   this.formData2.banks = resData.data[0].banks;
            // }
            // else if (this.$route.params.id == 1005){
            //   this.formData = resData.data[1];
            //   this.formData2.banks = resData.data[1].banks;
            // }
            // else if (this.$route.params.id == 1015){
            //   this.formData = resData.data[2];
            //   this.formData2.banks = resData.data[2].banks;
            // } else {
            //   this.$router.replace({ name: "Applicant" }).catch(() => {});
            // }
            this.formData = resData.data;
            this.formData2.banks = resData.data.banks;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          setTimeout(() => {
            this.loading = false;
          }, 500);
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    save() {
      this.submitted = true;
      this.$v.formData.$touch();
      this.$v.formData2.$touch();

      console.log(this.$v.formData.$invalid);
      if (this.$v.formData.$invalid || this.$v.formData2.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.buttonLoading[0] = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("accountDbCode", this.$route.params.id);
        bodyFormData.append("companyName", this.formData.company_name);
        bodyFormData.append("businessRegistrationNo", this.formData.business_registration_no);
        bodyFormData.append("dateOfRegistration", this.formData.date_of_registration);
        bodyFormData.append("typeOfEntity", JSON.stringify(this.formData.type_of_entity));
        bodyFormData.append("businessAddress", this.formData.business_address);
        bodyFormData.append("businessTel", this.formData.business_tel);
        bodyFormData.append("businessEmail", this.formData.business_email);
        bodyFormData.append("contactPerson", this.formData.contact_person);
        bodyFormData.append("position", this.formData.position);
        bodyFormData.append("contactTel", this.formData.contact_tel);
        bodyFormData.append("contactEmail", this.formData.contact_email);
        bodyFormData.append("directors", JSON.stringify(this.formData.directors));
        bodyFormData.append("banks", JSON.stringify(this.formData2.banks));
        bodyFormData.append("supportingDocs", JSON.stringify(this.formData.supporting_docs));
        bodyFormData.append("currentFacilitiesLimit", JSON.stringify(this.formData.current_facilities_limit));
        bodyFormData.append("active", this.formData.active);
        bodyFormData.append("reasonInactive", this.formData.reason_inactive);
        axios({
          method: "post",
          url:  appConfig.APIHost + "controller/applicant/editApplicant",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData);
              Swal.fire({
                icon: "success",
                title: "Update Applicant Information",
                html: "Applicant information has been successfully updated.",
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              }).then(() => {
                console.log("success");
                //this.getData()
                this.$router.replace({ name: "Applicant" }).catch(() => {});
              });
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.buttonLoading[0] = false;
            this.submitted = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.buttonLoading[0] = false;
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      }
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    uploadFile(value) {
      this.$Progress.start();
      this.buttonLoading[1] = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("mediaPath", "applicant_doc");
      //bodyFormData.append("id", value.id);
      bodyFormData.append("media", value.files);
      //bodyFormData.append("size", value.file_size);
     // bodyFormData.append("name", value.file_name);

      axios({
        method: "post",
        url: appConfig.APIHost + 'controller/media/saveMediaFile',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);
            value.status = 1;
            value.checkYesNo = "checked";
            value.remark = value.appendix;
            value.files = resData.media_path;
            console.log("uploaded");
            console.log(this.formData.supporting_docs);
            //upload to the server temporary place first and you return id to me, after that i submit the form you move the data to ori place
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.buttonLoading[1] = false;
          this.uploadProgress = 0;
          this.submitted = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.buttonLoading[1] = false;
          this.uploadProgress = 0;
          this.submitted = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='applicant')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "applicant" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    }
  }
};
</script>
